<template>
  <div>
    <div class="statis">
      <div class="box">
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
      </div>

      <div class="box">
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
      </div>

      <div class="box">
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
        <div class="box_top_1">
          <div class="box_top">
            <h3>总桩数：386根</h3>
            <h3>总延米：2935.42 m</h3>
            <h3>总浆量：208700 L</h3>
            <h3>总灰量：261042.97 t</h3>
          </div>
          <div class="box_top_bottom">石角咀水闸</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  },
}
</script>
<style scoped>
.statis {
  height: 860px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.box_top_1 {
  width: 22%;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.4);
}
.box_top {
  background: #087ad1;
  color: #fff;
  font-size: 22px;
  padding: 15px 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.box_top h3 {
  font-weight: normal;
}
.box_top_bottom {
  background: #fff;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
</style>
